import React, { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Link, navigate } from "gatsby"
import { Purchases, Files, Users } from "../../redux/api"
import {
  ABSTRACT_SUBMISSION,
  ABSTRACT_DETAIL,
  WATCH,
} from "../../constants/routes"
import { CONFIRM, VIEW_ABSTRACT } from "../../redux/actionTypes"
import { formatWithCurrency } from "../../utils/currency"
import ProfilePayment from "./profilePayment"
import Avatar from "../images/avatar.jpg"

const calcExpiredDate = d => {
  const date = new Date(d)
  date.setDate(date.getDate() + 14)
  return date
}

const ProfileMain = ({ state }) => {
  const dispatch = useDispatch()
  const [cart, setCart] = useState({ invoice: {}, items: [] })
  const [files, setFiles] = useState([])
  const [expiredDate, setExpiredDate] = useState([])
  const [diff, setDiff] = useState(null)
  useEffect(() => {
    const fetchData = async () => {
      const res = await Purchases.bought()
      const data = res ? await res.json() : null
      setCart(data)
      setExpiredDate(data ? calcExpiredDate(data.invoice.createdAt) : null)

      if (data?.invoice?.currency === "IDR") {
        const { total_price } = data.invoice
        const diff =
          total_price - data.items.reduce((a, c) => a + c.price_idr, 0)
        setDiff(diff)
      }

      const res2 = await Files.submitted()
      const data2 = res2 ? await res2.json() : null
      setFiles(data2 ? data2.files : [])
    }
    fetchData()
  }, [])

  const getPrice = item =>
    cart.invoice.currency === "USD" ? item.price_usd : item.price_idr

  const confirmAction = async () => {
    const res = await Users.confirm()
    const ok = res ? res.ok : null
    if (!ok) return null
    dispatch({ type: CONFIRM })
  }

  const profile = state.user.profile || {}
  const { confirmed, activated } = profile

  const PayTitle = profile.confirmed ? "" : "Unconfirmed "
  const PayButton = () => {
    const className = `button button-reg button-3d button-circle mr-0 text-center mb-3 submit float-right`
    const classConf = confirmed ? ` disable` : ` gradient-grey-orange`
    return (
      <button className={className + classConf} onClick={confirmAction}>
        {confirmed ? `Waiting` : `Confirm Payment`}
      </button>
    )
  }
  const PayAlert = () => {
    const exp = expiredDate ? expiredDate.toString() : null
    const classConf = confirmed ? ` alert-info` : ` alert-danger`
    return (
      <div className={"col-12 alert mb-5" + classConf}>
        <h5 className={"mb-0" + classConf}>
          <i className="icon-info-circle" style={{ top: "2px" }} />
          {confirmed
            ? `Wait for Admin's Confirmation`
            : `Complete Your Payment!`}
        </h5>
        <br />
        <span className="mb-0">
          {confirmed
            ? `Thanks for being participate. Please wait for the admin confirmation to activated your account`
            : `Please completed your payment before 18 March 2021 - 23.59 GMT+7`}
        </span>
      </div>
    )
  }

  const WatchRecording = () => {
    return (
      <Link to={WATCH}>
        <div className="col-12 alert mb-5 alert-info">
          <h5 className="mb-0 alert-info">
            <i className="icon-info-circle" style={{ top: "2px" }} />
            Watch the recording of the event here
          </h5>
          <br />
          <span className="mb-0">Click here to watch event recording</span>
        </div>
      </Link>
    )
  }

  const viewAbstractDetail = e => {
    const payload = files[e.target.value]
    dispatch({ type: VIEW_ABSTRACT, payload })
    navigate(ABSTRACT_DETAIL)
  }

  return (
    <div className="col-md-9">
      <img
        src={Avatar}
        className="alignleft img-circle img-thumbnail my-0"
        alt="Avatar"
        style={{ maxWidth: "84px" }}
      />
      <div className="border-0 mb-6">
        <h3 className="profile-name mb-0">{profile.fullname}</h3>
        <h5>{profile.country}</h5>
      </div>
      <div className="clear" />
      <div className="row clearfix">
        <div className="col-lg-12 mb-5">
          <div className="tab-content clearfix">
            <WatchRecording />
          </div>
        </div>
        {activated ? null : (
          <div className="col-lg-12 mb-5">
            <div className="tab-content clearfix" id="payment">
              <PayAlert />
              <div className="row mb-1">
                <div className="col-md-6">
                  <h4 className="pt-1">
                    {PayTitle}Payment Details
                    <br />
                    <span className="des">This is your payment details.</span>
                  </h4>
                </div>
                <div className="col-md-6 mb-4">
                  <PayButton></PayButton>
                </div>
              </div>
              <div className="table-responsive table-bordered mb-0">
                <table className="table" style={{ marginBottom: 0 }}>
                  <thead>
                    <tr>
                      <th className="cart-product-name">Category</th>
                      <th className="cart-product-name">Type</th>
                      <th className="cart-product-name min-amount">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {cart
                      ? cart.items.map((item, i) => {
                          return (
                            <tr key={i} className="cart_item">
                              <td className="cart-product-name">
                                <span
                                  className="amount-shade"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {item.category}
                                </span>
                              </td>
                              <td className="cart-product-name">
                                <span className="amount-shade">
                                  {item.name}
                                </span>
                              </td>
                              <td className="cart-product-name">
                                {/* <span className="amount-shade">Rp</span> */}
                                <span
                                  className="amount-number"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  {formatWithCurrency(
                                    getPrice(item),
                                    cart?.invoice?.currency
                                  )}
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      : null}
                    {cart?.invoice?.currency === "IDR" ? (
                      <tr className="cart_item">
                        <td className="cart-product-name">
                          <span className="amount-shade">Code</span>
                        </td>
                        <td className="cart-product-name">
                          <span className="amount-shade">
                            Unique Code to verify your Payment
                          </span>
                        </td>
                        <td className="cart-product-name">
                          <span className="amount-number">
                            {formatWithCurrency(diff, "IDR")}
                          </span>
                        </td>
                      </tr>
                    ) : null}
                    <tr className="cart_item">
                      <td
                        className="cart-product-name"
                        style={{ borderTop: "3px solid #b2b2b2" }}
                      >
                        <span />
                      </td>
                      <td
                        className="cart-product-name"
                        style={{ borderTop: "3px solid #b2b2b2" }}
                      >
                        <h4>Total</h4>
                      </td>
                      <td
                        className="cart-product-name"
                        style={{ borderTop: "3px solid #b2b2b2" }}
                      >
                        {/* <h4 className="amount-shade">Rp</h4> */}
                        <h4 className="amount-number color-apastd">
                          {cart
                            ? formatWithCurrency(
                                cart.invoice.total_price,
                                cart.invoice.currency
                              )
                            : null}
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* Modal */}
            <div className="modal1 mfp-hide" id="modalpayment">
              <div
                className="block mx-auto"
                style={{ backgroundColor: "#FFF", maxWidth: "500px" }}
              >
                <div className="center" style={{ padding: "50px" }}>
                  <h3>Payment Confirmed</h3>
                  <p className="mb-0">
                    Please wait our administrator to verify your payment and
                    activate your account.
                  </p>
                </div>
                <div className="section center m-0" style={{ padding: "30px" }}>
                  <a
                    href="profile-confirm.html"
                    className="button button-3d button-circle gradient-grey-orange mr-0"
                  >
                    Close
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {activated ? null : <ProfilePayment></ProfilePayment>}
      </div>
      <div className="divider divider-center">
        <i className="icon-circle" />
      </div>
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="clearfix" id="abstract">
            <div className="row mb-1">
              <div className="col-md-6">
                <h3 className="float-left vertical-center mb-5">
                  {" "}
                  Abstract Submissions{" "}
                </h3>
              </div>
              <div className="col-md-6 mb-4">
                <Link
                  to={ABSTRACT_SUBMISSION}
                  className={
                    "button button-circle button-log float-right submit" +
                    (false ? `` : ` disable`)
                  }
                >
                  Add New Abstract
                </Link>
              </div>
            </div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Title</th>
                  <th>Author</th>
                  <th>Category</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{file.title}</td>
                    <td>{file.author}</td>
                    <td>{file.category}</td>
                    <td>{file.status}</td>
                    <td>
                      <button
                        value={i}
                        onClick={viewAbstractDetail}
                        className="button button-3d button-circle gradient-grey-orange mr-0 abs-btn"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
                {files.length ? null : (
                  <tr>
                    <td colSpan={6}>
                      <span style={{ display: "block", textAlign: "center" }}>
                        Data Not Found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return { state }
}

export default connect(mapStateToProps)(ProfileMain)
