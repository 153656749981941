import React from "react"

const ProfilePayment = () => {
  return (
    <div>
      <div className="col-12 mb-4">
        <h4>
          Payment Info
          <br />
          <span className="des">
            All payment is using our official bank account
          </span>
        </h4>
        <table className="table">
          <tbody>
            <tr>
              <td>
                <span>Bank Name</span>
              </td>
              <td>
                <span />
                Bank Mandiri
              </td>
            </tr>
            <tr>
              <td>
                <span>Account Name</span>
              </td>
              <td>
                <span />
                PABOI Cabang Jawa Timur
              </td>
            </tr>
            <tr>
              <td>
                <span>Account Number</span>
              </td>
              <td>
                <span />
                142 - 00 - 1822112 - 6
              </td>
            </tr>
            <tr>
              <td>
                <span>
                  Swift Code <br />
                  (for international participant)
                </span>
              </td>
              <td>
                <span />
                BMRIIDJA
              </td>
            </tr>
            <tr>
              <td>
                <span />
              </td>
              <td>
                <span />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-12 mb-0">
        <h4>
          Payment Guidelines
          <br />
          <span className="des">
            Please carefully read payment instruction below:
          </span>
        </h4>
        <table className="table">
          <tbody>
            <tr>
              <td>
                <span>1.</span>
              </td>
              <td>
                <span />
                Payment amount above (unique code included) are only valid for 2
                weeks after registration succeed.
              </td>
            </tr>
            <tr>
              <td>
                <span>2.</span>
              </td>
              <td>
                <span />
                If the payment is not done after 2 weeks, this account will be
                deleted and you will be forced to create new account.
              </td>
            </tr>
            <tr>
              <td>
                <span>3.</span>
              </td>
              <td>
                <span />
                After payment is done, click "Confirm" button above.
              </td>
            </tr>
            <tr>
              <td>
                <span>4.</span>
              </td>
              <td>
                <span />
                Kindly wait our administrator to verify your payment.
              </td>
            </tr>
            <tr>
              <td>
                <span />
              </td>
              <td>
                <span />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ProfilePayment
