import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProfileMain from "../components/part/profileMain"
import ProfileAux from "../components/part/profileAux"

const ProfilePage = () => {
  const isLoggedIn = () => true
  if (!isLoggedIn()) {
    navigate("/login")
    return null
  }
  return (
    <Layout>
      <section id="content">
        <div className="content-wrap" style={{ marginTop: "32px" }}>
          <div className="container clearfix">
            <div className="row clearfix">
              <SEO title="Profile" />
              <ProfileMain></ProfileMain>
              <div className="w-100 line d-block d-md-none"></div>
              <ProfileAux></ProfileAux>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProfilePage
