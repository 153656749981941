import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { LOGOUT } from "../../redux/actionTypes"
import { LOGIN } from "../../constants/routes"
import { Purchases } from "../../redux/api"

const ProfileAux = ({ dispatch, state }) => {
  const [cart, setCart] = useState({ invoice: {}, items: [] })
  const [profile, setProfile] = useState({})
  const handleLogout = () => {
    dispatch({ type: LOGOUT })
    navigate(LOGIN)
  }
  useEffect(() => {
    const profile = state.user.profile
    setProfile(profile ? profile : {})
    if (!profile) return
    const fetchData = async () => {
      const res = await Purchases.bought()
      const data = res ? await res.json() : null
      setCart(data)
    }
    fetchData()
  }, [state.user.profile])

  return (
    <div className="col-md-3 pd-reg">
      <div className="fancy-title top-mg title-border">
        <h4>My Profile</h4>
      </div>
      <h5 className="mb-0">Category</h5>
      <p>{cart.items[0] ? cart.items[0].name : ""}</p>
      <h5 className="mb-0">Gender</h5>
      <p style={{ textTransform: "capitalize" }}>{profile.gender}</p>
      <h5 className="mb-0">E-mail</h5>
      <p>{profile.email}</p>
      <h5 className="mb-0">Phone Number</h5>
      <p>{profile.phone}</p>

      <button
        onClick={handleLogout}
        className="button button-circle button-border button-white button-reg mr-0 text-center"
        data-lightbox="inline"
      >
        Log Out
      </button>
    </div>
  )
}

const mapStateToProps = state => {
  return { state }
}

export default connect(mapStateToProps)(ProfileAux)
